export const churrasList = [
  {
    id: '1',
    date: '01/12',
    name: 'Níver do Gui',
    people: [
      {
        id: '1',
        name: 'Alice',
        value: 2000,
        status: false,
      },
      {
        id: '2',
        name: 'Beto',
        value: 2500,
        status: true,
      }
    ]
  },
  {
    id: '2',
    date: '23/12',
    name: 'Final de Ano',
    people: [
      {
        id: '1',
        name: 'Gabriel',
        value: 1500,
        status: true,
      },
      {
        id: '2',
        name: 'Paulo ',
        value: 3500,
        status: false,
      }
    ]
  }
]

import { Container, Title } from './styles';

function Header() {
  return (
    <Container>
      <Title>Agenda de Churras</Title>
    </Container>
  );
}

export default Header;
